import { useSelector } from 'react-redux';
import { FEATURES, isFeatureEnabled } from 'common';
import { useEffect, useState } from 'react';
import { isProductSoldOut, isStoreEnabledProductSoldOut } from './util';
export function useIsSoldOut(product) {
    const stock = useSelector((state) => state.products.stock);
    const deliveryDate = useSelector((state) => state.checkout.deliveryDate);
    const isStoreEnabled = useSelector(({ features }) => isFeatureEnabled(features.enabled, FEATURES.STORE));
    const [isSoldOut, setSoldOut] = useState(false);
    useEffect(() => {
        if (!product)
            return;
        const products = Array.isArray(product) ? product : [product];
        if (isStoreEnabled) {
            setSoldOut(products.some(p => isStoreEnabledProductSoldOut(p, deliveryDate)));
        }
        else {
            setSoldOut(products.some(p => isProductSoldOut(stock, p)));
        }
    }, [stock, product, deliveryDate, isStoreEnabled]);
    return isSoldOut;
}
