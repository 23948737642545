import { useSelector } from 'react-redux';
import { isProductSoldOut, ProductContext } from 'product';
import React, { useContext } from 'react';
import ItemCarouselHeading from 'components/ItemCarouselHeading/ItemCarouselHeading';
import BundleProductGrid from '../../../product/components/BundleProductGrid/BundleProductGrid';
import BundleProductCarousel from '../../../product/components/BundleProductCarousel/BundleProductCarousel';
import { FEATURES, isFeatureEnabled } from 'common';
import { isStoreEnabledProductSoldOut } from '../../../product/util';
function EditorialBundleProducts({ title, subtitle, mobileSubtitle, displayProducts, leadText, bundles, }) {
    const isMobile = useSelector(({ ui }) => ui.isMobile);
    const stock = useSelector((state) => state.products.stock);
    const deliveryDate = useSelector((state) => state.checkout.deliveryDate);
    const isStoreEnabled = useSelector(({ features }) => isFeatureEnabled(features.enabled, FEATURES.STORE));
    const { context: parentContext } = useContext(ProductContext);
    const slicedBundleProducts = bundles
        .filter((bundle) => {
        return isStoreEnabled
            ? !bundle.products.some((product) => isStoreEnabledProductSoldOut(product, deliveryDate))
            : !bundle.products.some((product) => isProductSoldOut(stock, product));
    })
        .slice(0, displayProducts);
    if (slicedBundleProducts.length === 0) {
        return null;
    }
    return (React.createElement(ProductContext.Provider, { value: { parentContext, context: 'bundle' } },
        React.createElement("div", { className: "editorial-products" },
            React.createElement(ItemCarouselHeading, { title: title, subtitle: isMobile ? mobileSubtitle : subtitle }),
            !isMobile && leadText && (React.createElement("p", { className: "editorial-products__lead-text" }, leadText)),
            isMobile ? (React.createElement(BundleProductCarousel, { bundles: slicedBundleProducts })) : (React.createElement(BundleProductGrid, { bundles: slicedBundleProducts })))));
}
export default EditorialBundleProducts;
